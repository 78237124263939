<template>
  <div>
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="操作类型">
            {{ batchOperationRecordInfo.operation_type_display }}
          </a-descriptions-item>

          <a-descriptions-item label="操作批次">
            {{ batchOperationRecordInfo.operation_no }}
          </a-descriptions-item>

          <a-descriptions-item label="操作时间">
            {{ batchOperationRecordInfo.created_at }}
          </a-descriptions-item>

          <a-descriptions-item label="备注">
            <span class="simiot-word-break-all">
              {{ batchOperationRecordInfo.remark }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="修改字段">
            {{ batchOperationRecordInfo.modify_column }}
          </a-descriptions-item>

          <a-descriptions-item label="操作方式">
            {{ batchOperationRecordInfo.operator_type_display }}
          </a-descriptions-item>

          <a-descriptions-item label="操作人">
            {{ batchOperationRecordInfo.operator_name }}
          </a-descriptions-item>

          <a-descriptions-item label="重跑次数">
            {{ batchOperationRecordInfo.run_times }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="卡号总数">
            {{ batchOperationRecordInfo.operation_count }}
          </a-descriptions-item>

          <a-descriptions-item label="操作失败数">
            {{ batchOperationRecordInfo.failure_count }}
          </a-descriptions-item>

          <a-descriptions-item label="操作成功数">
            {{ batchOperationRecordInfo.success_count }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'BatchOperationDetailBatchModifyInfo',
  props: {
    batchOperationRecordInfo: {
      type: Object,
      required: true
    }
  }
}
</script>

